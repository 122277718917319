import { IconPlus, IconX } from "@tabler/icons-react";
import { Text, Button, Grid, SegmentedControl, Switch } from "@mantine/core";

function FlexCross({ strategy, updateStrategy, removeStrategy }) {
  return (
    <Grid>
      <Grid.Col lg={3}>
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          // color="violet"
          onClick={() => removeStrategy(strategy)}
        >
          F.Cross
        </Button>
      </Grid.Col>
      <Grid.Col lg={9}>
        <Grid>
          <Grid.Col lg={8}>
            <SegmentedControl
              value={strategy.params.interval}
              onChange={(value) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, interval: value },
                })
              }
              data={[
                { value: "1m", label: "1m" },
                { value: "3m", label: "3m" },
                { value: "5m", label: "5m" },
                { value: "15m", label: "15m" },
                { value: "1h", label: "1h" },
                { value: "4h", label: "4h" },
                { value: "1d", label: "1d" },
                { value: "1w", label: "1w" },
              ]}
            />
          </Grid.Col>
          <Grid.Col
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              paddingBottom: "1rem",
            }}
          >
            <Switch
              label={<b>AND</b>}
              checked={strategy.params.and}
              onClick={(e) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, and: e.target.checked },
                })
              }
            />
          </Grid.Col>
          <Grid.Col lg={12}>
            <SegmentedControl
              value={strategy.params.cross}
              onChange={(value) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, cross: value },
                })
              }
              data={[
                { value: "3", label: "1x3" },
                { value: "7", label: "1x7" },
                { value: "15", label: "1x15" },
              ]}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

FlexCross.type = "sell";
FlexCross.color = "violet";
FlexCross.model = {
  name: "flex_cross",
  title: "Flex cross",
  // algorithm: "flex_cross",
  params: {
    interval: "1m",
    cross: "3",
  },
};
FlexCross.paramsTitle = (strategy) => {
  return `${strategy.params.interval}, 1x${strategy.params.cross}`;
};

FlexCross.Button = function (props) {
  return (
    <Button variant="default" color="violet" leftIcon={<IconPlus />} {...props}>
      F.Cross
    </Button>
  );
};

export default FlexCross;
