import { Center, Grid, Text, Title, ActionIcon } from "@mantine/core";
import { IconStarFilled } from "@tabler/icons-react";

import Table, { TH } from "../../common/components/Table";
import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";
import { toggleStarred } from "../../common/data/starred/redux";
import { useDispatch, useSelector } from "react-redux";
import { Platform, Symbol, SymbolTH } from "../common";

export default function FlashCrashTable({
  token,
  tokens,
  sortBy,
  sortDir,
  setSortBy,
  setSortDir,
}) {
  return (
    <Table>
      <Thead
        sortBy={sortBy}
        sortDir={sortDir}
        setSortBy={setSortBy}
        setSortDir={setSortDir}
      />
      <Tbody token={token} tokens={tokens} />
    </Table>
  );
}

const Thead = ({ sortBy, sortDir, setSortBy, setSortDir }) => {
  return (
    <thead>
      <tr>
        <SymbolTH />
        <TH
          title="dropped"
          sortBy="droppedBy"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="Δ"
          sortBy="delta"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH title="plt" />
        <TH title="btn" />
      </tr>
    </thead>
  );
};

const Tbody = ({ token, tokens }) => {
  const dispatch = useDispatch();
  const starred = useSelector((state) => state.starred);

  return (
    <tbody>
      {(token ? [token] : tokens).map(
        ({ symbol, platform, ll, droppedBy, delta }) => {
          let onlySymbol = symbol.replace("USDT", "");

          return (
            <tr key={symbol}>
              <td>
                <Symbol symbol={symbol} platform={platform} ll={ll} />
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  ↓ {droppedBy.toFixed(0)}%{" "}
                </Text>
              </td>
              <td>
                <Center>
                  <Text
                    size="sm"
                    weight={600}
                    color={
                      delta >= 1 ? "green.9" : delta >= 0 ? "dark.9" : "red.9"
                    }
                  >
                    {delta === undefined
                      ? ""
                      : delta >= 1
                      ? `+${delta}%`
                      : `${delta}%`}
                  </Text>
                </Center>
              </td>
              <td>
                <Platform symbol={symbol} platform={platform} />
              </td>
              <td>
                <Center>
                  <ActionIcon
                    variant="transparent"
                    color={starred.tokens?.[onlySymbol] ? "teal.7" : "gray.2"}
                    onClick={() => {
                      dispatch(
                        toggleStarred({
                          symbol,
                          platform,
                          value: !!starred.tokens?.[onlySymbol],
                        })
                      );
                    }}
                  >
                    <IconStarFilled size="1.2rem" />
                  </ActionIcon>
                </Center>
              </td>
            </tr>
          );
        }
      )}
    </tbody>
  );
};
