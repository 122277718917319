import { Fragment, React } from "react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Group,
  Modal,
  Text,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import { IconCurrencyDollar } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";

import AmountPicker from "./AmountPicker";
import AmountUsdPicker from "./AmountUsdPicker";
import AmountPercentPicker from "./AmountPercentPicker";

import { toK } from "../../common/helpers";

export const AmountStrategyInitModel = {
  // * straight
  amount: 0,
  amountUsd: 0,
  amountPercent: 0,
  // * volume
  minInvestAmount: 50,
  maxInvestAmount: 20000,
  maxDepositUsePercent: 50,
};

export const AmountStrategyBoardButton = ({
  symbol,
  amountStrategy,
  openModal,
  isFake,
  disableButtons,
}) => {
  let desc = "-";
  let {
    amount,
    amountUsd,
    amountPercent,
    maxDepositUse,
    maxDepositUsePercent,
    minInvestAmount,
    maxInvestAmount,
  } = amountStrategy || {};
  amount = Number(amount);
  amountUsd = Number(amountUsd);
  amountPercent = Number(amountPercent);
  maxDepositUse = Number(maxDepositUse);
  maxDepositUsePercent = Number(maxDepositUsePercent);
  minInvestAmount = Number(minInvestAmount);
  maxInvestAmount = Number(maxInvestAmount);

  if (amount) {
    desc = `${amount} ${symbol}`;
  } else if (amountUsd) {
    desc = `${amountUsd}$`;
  } else if (amountPercent) {
    desc = `${amountPercent}% ${symbol}`;
  } else {
    desc = `${toK(minInvestAmount)}$ - ${toK(maxInvestAmount)}$`;
    if (maxDepositUse) desc += `, max.${maxDepositUse}$`;
    else if (maxDepositUsePercent) desc += `, max.${maxDepositUsePercent}%`;
  }

  return (
    <Fragment>
      <UnstyledButton
        key="amount"
        onClick={openModal}
        disabled={disableButtons}
      >
        <Group>
          <Avatar size={40} color={isFake ? "gray" : "teal"}>
            <IconCurrencyDollar />
          </Avatar>
          <div>
            <Text fw={700} size="sm">
              Amount
            </Text>
            <Text size="sm">{desc}</Text>
          </div>
        </Group>
      </UnstyledButton>
    </Fragment>
  );
};

export const AmountStrategyPickerModal = ({
  show,
  amountStrategy,
  setAmountStrategy,
  onClose,
  onSubmit,
  process,
  hideByVolume,
}) => {
  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  const updateStrategy = () => {};

  return (
    <Modal
      opened={show}
      onClose={onClose}
      centered={true}
      withCloseButton={false}
      size={isSmallerThanSmall ? "100%" : isSmallerThanLarge ? "75%" : "50%"}
      trapFocus={false}
      transitionProps={{ duration: 0 }}
      lockScroll={false}
    >
      <Grid>
        <Grid.Col span={12}>
          <Divider size="lg" label={<b>Straight</b>} labelPosition="center" />
        </Grid.Col>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col span={4}>
              <AmountUsdPicker
                amountStrategy={amountStrategy}
                setAmountStrategy={setAmountStrategy}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <AmountPicker
                amountStrategy={amountStrategy}
                setAmountStrategy={setAmountStrategy}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <AmountPercentPicker
                amountStrategy={amountStrategy}
                setAmountStrategy={setAmountStrategy}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        {!hideByVolume && (
          <Fragment>
            <Grid.Col span={12}>
              <Divider
                size="lg"
                label={<b>By Volume </b>}
                labelPosition="center"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Grid>
                <Grid.Col span={4}>
                  <TextInput
                    label={<Text fw={700}>Min. Inv. Amount ($)</Text>}
                    placeholder="Max. Inv. Amount ($)"
                    value={String(amountStrategy.minInvestAmount)}
                    onChange={(e) => {
                      setAmountStrategy((current) => ({
                        ...current,
                        minInvestAmount: e.target.value
                          ? Number(e.target.value)
                          : 0,
                      }));
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    label={<Text fw={700}>Max. Inv. Amount ($)</Text>}
                    placeholder="Max. Inv. Amount ($)"
                    value={String(amountStrategy.maxInvestAmount)}
                    onChange={(e) => {
                      setAmountStrategy((current) => ({
                        ...current,
                        maxInvestAmount: e.target.value
                          ? Number(e.target.value)
                          : 0,
                      }));
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    label={<Text fw={700}>Max Deposit Use (%)</Text>}
                    placeholder="Max Deposit Use (%)"
                    value={String(amountStrategy.maxDepositUsePercent)}
                    onChange={(e) => {
                      setAmountStrategy((current) => ({
                        ...AmountStrategyInitModel,
                        minInvestAmount: current.minInvestAmount,
                        maxInvestAmount: current.maxInvestAmount,
                        maxDepositUsePercent: e.target.value
                          ? Number(e.target.value)
                          : 0,
                      }));
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Fragment>
        )}

        <Grid.Col span={6}>
          <Button
            fullWidth
            variant="light"
            color="blue"
            onClick={onSubmit}
            loading={process?.inProcess}
            disabled={process?.inProcess}
          >
            Submit
          </Button>
        </Grid.Col>
        <Grid.Col span={6}>
          <Button fullWidth variant="white" color="gray" onClick={onClose}>
            Close
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};
