import { useEffect, useState } from "react";
import { Box, Button, Grid, Group } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconEyeOff, IconArrowsExchange } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";

import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";
import { CREATE_ORDER, createOrder } from "../../common/data/orders/redux";
import {
  subscribeToPrice,
  unsubscribeById,
} from "../../common/data/currentPrice/redux";

import SymbolPicker from "../SymbolPicker";
import AmountPicker from "../AmountStrategyPicker/AmountPicker";
import AmountUsdPicker from "../AmountStrategyPicker/AmountUsdPicker";
import { StrategyPickerModal, StrategyPickerBoard } from "../StrategyPicker";
import AmountPercentPicker from "../AmountStrategyPicker/AmountPercentPicker";
import { toFixed } from "../../common/helpers";
import { useMediaQuery } from "@mantine/hooks";
import {
  AmountStrategyBoardButton,
  AmountStrategyPickerModal,
  AmountStrategyInitModel,
} from "../AmountStrategyPicker";
import { toggleStarred } from "../../common/data/starred/redux";
import StarButton from "../StarButton";

const CreateOrderButton = ({
  symbol,
  type,
  action,
  amountStrategy,
  create,
}) => {
  let currentPrice = useSelector((state) => {
    return state.currentPrice[`${symbol}USDT`];
  });
  currentPrice = currentPrice || 0;

  const createOrderProcess = useSelector(
    (state) => state.processes[CREATE_ORDER]
  );

  return (
    <Button
      w="100%"
      variant="light"
      color={type === "fake" ? "gray" : action === "buy" ? "teal" : "red"}
      loading={createOrderProcess?.inProcess}
      disabled={createOrderProcess?.inProcess}
      onClick={create}
    >
      {action === "buy" ? "Buy" : "Sell"}{" "}
      {+amountStrategy.amountUsd
        ? `${amountStrategy.amountUsd}$ ${symbol.replace("USDT", "")}`
        : +amountStrategy.amountPercent
        ? `${amountStrategy.amountPercent}% ${symbol.replace("USDT", "")}`
        : +amountStrategy.amount
        ? `${amountStrategy.amount} ${symbol.replace("USDT", "")} = ${
            currentPrice
              ? toFixed(amountStrategy.amount * currentPrice, 0)
              : "_"
          }$`
        : null}
    </Button>
  );
};

export function NewOrderShort({
  initSymbol,
  initPlatform,
  hideForm,
  showStarButton = true,
}) {
  const dispatch = useDispatch();

  const [searchParams, _] = useSearchParams();

  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  // symbol & platform & action & type
  const [symbol, setSymbol] = useState(() => initSymbol || "BTC");
  const [debouncedSymbol] = useDebouncedValue(symbol, 300);
  const [platform, setPlatform] = useState(() => initPlatform || "binance");

  useEffect(() => {
    if (!debouncedSymbol || !platform) return;
    dispatch(unsubscribeById({ id: "new_order" }));
    dispatch(
      subscribeToPrice({
        symbol: `${debouncedSymbol}USDT`,
        id: "new_order",
        platform,
      })
    );
  }, [debouncedSymbol, platform]);

  const onChangeSymbol = (symbol) => {
    if (!symbol) {
      return;
    }

    setSymbol(symbol.toUpperCase());
  };

  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <TradingViewAdvancedChartWidget
            symbol={`${symbol}USDT`}
            platform={platform.toUpperCase()}
            width="100%"
            height={isSmallerThanLarge ? "400px" : "500px"}
            interval="1d"
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col lg={3} md={3} sm={6} xs={6} span={6}>
              <SymbolPicker value={symbol} onChange={onChangeSymbol} />
            </Grid.Col>
            <Grid.Col lg={3} md={3} sm={6} xs={6} span={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Button
                  w="100%"
                  variant="white"
                  color="gray"
                  onClick={() =>
                    platform === "binance"
                      ? setPlatform("bybit")
                      : platform === "bybit"
                      ? setPlatform("mexc")
                      : setPlatform("binance")
                  }
                >
                  {platform}
                </Button>
              </Box>
            </Grid.Col>
            <Grid.Col lg={4} md={4} sm={6} xs={6} span={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  height: "100%",
                }}
              >
                <NavLink
                  to={`/trade?symbol=${symbol}&platform=${platform}&show=all`}
                  style={{ width: "100%" }}
                >
                  <Button w="100%" variant="light" color="teal">
                    Trade
                  </Button>
                </NavLink>
              </Box>
            </Grid.Col>
            {showStarButton && (
              <Grid.Col lg={2} md={2} sm={6} xs={6} span={6}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    paddingBottom: "0.5rem",
                    height: "100%",
                  }}
                >
                  <StarButton symbol={symbol} platform={platform} />
                </Box>
              </Grid.Col>
            )}
          </Grid>
        </Grid.Col>
      </Grid>
    </>
  );
}

export default function NewOrder({
  initSymbol,
  initPlatform,
  hideForm,
  showStarButton = true,
}) {
  const dispatch = useDispatch();

  const [searchParams, _] = useSearchParams();

  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  // symbol & platform & action & type
  const [symbol, setSymbol] = useState(
    () => initSymbol || searchParams.get("symbol") || "BTC"
  );
  const [debouncedSymbol] = useDebouncedValue(symbol, 300);
  const [platform, setPlatform] = useState(
    () => initPlatform || searchParams.get("platform") || "binance"
  );
  const [action, setAction] = useState(
    () => searchParams.get("action") || "buy"
  );
  const [type, setType] = useState(() => searchParams.get("type") || "real");
  // amount strategy picker
  const [showAmountStrategyPicker, setShowAmountStrategyPicker] =
    useState(false);
  const [amountStrategy, setAmountStrategy] = useState({
    ...AmountStrategyInitModel,
  });
  // buy & sell & repeat strategy picker
  const [showStrategyPicker, setShowStrategyPicker] = useState(false);
  const [buyStrategies, setBuyStrategies] = useState([]);
  const [sellStrategies, setSellStrategies] = useState([]);
  const [repeatStrategies, setRepeatStrategies] = useState([]);

  // console.log(currentPrice);

  // binance price
  // const { lastMessage: binanceMessage } = useWebSocket(
  //   `wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@kline_1s`
  // );
  // const binanceData = binanceMessage && JSON.parse(binanceMessage.data);
  // const binancePrice = parseFloat(binanceData?.k?.c) || 0;
  // const currentPrice = platform === "binance" ? binancePrice : 0;

  useEffect(
    function updateSymbol() {
      if (searchParams.get("symbol") && !initSymbol) {
        let symbol = searchParams.get("symbol");

        setSymbol(symbol.replace("USDT", ""));
      }
    },
    [searchParams.get("symbol")]
  );

  useEffect(
    function updatePlatform() {
      if (searchParams.get("platform") && !initPlatform) {
        setPlatform(searchParams.get("platform"));
      }
    },
    [searchParams.get("platform")]
  );

  useEffect(
    function resetRepeatStrategies() {
      if (!buyStrategies.length && !sellStrategies.length) {
        setRepeatStrategies([]);
      }
    },
    [buyStrategies, sellStrategies]
  );

  useEffect(
    function resetStrategiesOnSingleSell() {
      if (action === "sell") {
        setAmountStrategy({ ...AmountStrategyInitModel });
        setBuyStrategies([]);
        setRepeatStrategies([]);
      }
    },
    [action]
  );

  useEffect(() => {
    if (!debouncedSymbol || !platform) return;
    dispatch(unsubscribeById({ id: "new_order" }));
    dispatch(
      subscribeToPrice({
        symbol: `${debouncedSymbol}USDT`,
        id: "new_order",
        platform,
      })
    );
  }, [debouncedSymbol, platform]);

  const onChangeSymbol = (symbol) => {
    if (!symbol) {
      return;
    }

    setSymbol(symbol.toUpperCase());
  };

  const create = () => {
    dispatch(
      createOrder({
        symbol: `${symbol}USDT`,
        platform,
        action,
        isFake: type === "fake",
        amountStrategy: amountStrategy,
        buyStrategies: buyStrategies,
        sellStrategies: sellStrategies,
        repeatStrategies: repeatStrategies,
      })
    );
  };

  return (
    <>
      <AmountStrategyPickerModal
        show={showAmountStrategyPicker}
        amountStrategy={amountStrategy}
        setAmountStrategy={setAmountStrategy}
        onClose={() => setShowAmountStrategyPicker(false)}
        onSubmit={() => setShowAmountStrategyPicker(false)}
        hideByVolume={action === "sell"}
      ></AmountStrategyPickerModal>
      <StrategyPickerModal
        symbol={symbol}
        show={showStrategyPicker}
        buyStrategies={buyStrategies}
        setBuyStrategies={setBuyStrategies}
        sellStrategies={sellStrategies}
        setSellStrategies={setSellStrategies}
        repeatStrategies={repeatStrategies}
        setRepeatStrategies={setRepeatStrategies}
        onClose={() => setShowStrategyPicker(false)}
        onSubmit={() => setShowStrategyPicker(false)}
        disableBuyStrategies={action === "sell"}
        disableRepeatStrategies={action === "sell"}
      ></StrategyPickerModal>
      <Grid>
        <Grid.Col span={12}>
          <TradingViewAdvancedChartWidget
            symbol={`${symbol}USDT`}
            platform={platform.toUpperCase()}
            width="100%"
            height={isSmallerThanLarge ? "400px" : "500px"}
            interval="1d"
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Box shadow="xs">
            <Grid>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={hideForm}
                  >
                    <IconEyeOff />
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() =>
                      platform === "binance"
                        ? setPlatform("bybit")
                        : platform === "bybit"
                        ? setPlatform("mexc")
                        : setPlatform("binance")
                    }
                  >
                    {platform}
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() => {
                      setAction((action) =>
                        action === "buy" ? "sell" : "buy"
                      );
                    }}
                  >
                    <IconArrowsExchange />
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() =>
                      setType((type) => (type === "real" ? "fake" : "real"))
                    }
                  >
                    {
                      type === "real"
                        ? "real"
                        : // <IconMasksTheaterOff />
                          "fake"
                      // <IconMasksTheater />
                    }
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <SymbolPicker value={symbol} onChange={onChangeSymbol} />
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <AmountUsdPicker
                  amountStrategy={amountStrategy}
                  setAmountStrategy={setAmountStrategy}
                />
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <AmountPicker
                  amountStrategy={amountStrategy}
                  setAmountStrategy={setAmountStrategy}
                />
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <AmountPercentPicker
                  amountStrategy={amountStrategy}
                  setAmountStrategy={setAmountStrategy}
                />
              </Grid.Col>
              <Grid.Col xl={4} lg={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <CreateOrderButton
                    symbol={symbol}
                    type={type}
                    action={action}
                    amountStrategy={amountStrategy}
                    create={create}
                  />
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
        <Grid.Col span={12}>
          <Group
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AmountStrategyBoardButton
              symbol={symbol}
              amountStrategy={amountStrategy}
              openModal={() => setShowAmountStrategyPicker(true)}
              isFake={type === "fake"}
            />
            <StrategyPickerBoard
              buyStrategies={buyStrategies}
              sellStrategies={sellStrategies}
              repeatStrategies={repeatStrategies}
              openModal={() => setShowStrategyPicker(true)}
              isFake={type === "fake"}
            />
            {showStarButton && (
              <StarButton symbol={symbol} platform={platform} />
            )}
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
}
