import { createSlice } from "@reduxjs/toolkit";

export const CREATE_ORDER = "ORDERS::CREATE_ORDER";
export const UPDATE_ORDER = "ORDERS::UPDATE_ORDER";
export const ORDER_BUY = "ORDERS::ORDER_BUY";
export const ORDER_SELL = "ORDERS::ORDER_SELL";
export const ORDER_STOP = "ORDERS::ORDER_STOP";
export const ORDER_RESUME = "ORDERS::ORDER_RESUME";
export const ORDER_CLOSE = "ORDERS::ORDER_CLOSE";
export const ORDER_DELETE = "ORDERS::ORDER_DELETE";

export const WATCH_ORDERS = "ORDERS::WATCH";

export const createOrder = ({
  symbol,
  platform,
  amount,
  amountUsd,
  amountPercent,
  action,
  isFake,
  amountStrategy,
  buyStrategies,
  sellStrategies,
  repeatStrategies,
} = {}) => ({
  type: CREATE_ORDER,
  symbol,
  platform,
  amount,
  amountUsd,
  amountPercent,
  action,
  isFake,
  amountStrategy,
  buyStrategies,
  sellStrategies,
  repeatStrategies,
});
export const orderBuy = ({ id } = {}) => ({
  type: ORDER_BUY,
  id,
});
export const orderSell = ({ id } = {}) => ({
  type: ORDER_SELL,
  id,
});
export const orderStop = ({ id } = {}) => ({
  type: ORDER_STOP,
  id,
});
export const orderResume = ({ id } = {}) => ({
  type: ORDER_RESUME,
  id,
});

export const orderClose = ({ id } = {}) => ({
  type: ORDER_CLOSE,
  id,
});

export const orderDelete = ({ id } = {}) => ({
  type: ORDER_DELETE,
  id,
});

export const updateOrder = ({ id, updateOb, onSuccess } = {}) => ({
  type: UPDATE_ORDER,
  id,
  updateOb,
  onSuccess,
});

export const watchOrders = ({} = {}) => ({ type: WATCH_ORDERS });

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    real: [],
    fake: [],
  },
  reducers: {
    update: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
