import {
  take,
  fork,
  cancel,
  call,
  cancelled,
  put,
  delay,
  select,
} from "redux-saga/effects";
import axios from "axios";

import Mongo from "../../sevices/mongo";
import { watchOrders } from "./redux";

import {
  ordersSlice,
  WATCH_ORDERS,
  UPDATE_ORDER,
  CREATE_ORDER,
  ORDER_SELL,
  ORDER_BUY,
  ORDER_STOP,
  ORDER_RESUME,
  ORDER_CLOSE,
  ORDER_DELETE,
} from "./redux";
import { processesSlice } from "../processes/redux";
import { notifications } from "@mantine/notifications";

const getDb = (isFake) => (isFake ? Mongo.dbs.test : Mongo.dbs.main);
const getReduxKey = (isFake) => (isFake ? "fake" : "real");

// api functions

export function* createOrder() {
  while (true) {
    const {
      symbol,
      platform,
      amount,
      amountUsd,
      amountPercent,
      action,
      isFake,
      amountStrategy,
      buyStrategies,
      sellStrategies,
      repeatStrategies,
    } = yield take(CREATE_ORDER);

    try {
      yield put(
        processesSlice.actions.update({ key: CREATE_ORDER, inProcess: true })
      );

      amountStrategy.amount = Number(amountStrategy.amount);
      amountStrategy.amountUsd = Number(amountStrategy.amountUsd);
      amountStrategy.amountPercent = Number(amountStrategy.amountPercent);
      amountStrategy.minInvestAmount = Number(amountStrategy.minInvestAmount);
      amountStrategy.maxInvestAmount = Number(amountStrategy.maxInvestAmount);
      amountStrategy.maxDepositUsePercent = Number(
        amountStrategy.maxDepositUsePercent
      );

      yield call(axios, {
        url: "/api/create-order",
        method: "post",
        data: {
          symbol,
          platform,
          amount,
          amountUsd,
          amountPercent,
          action,
          isFake,
          amountStrategy,
          buyStrategies,
          sellStrategies,
          repeatStrategies,
        },
      });

      yield put(
        processesSlice.actions.update({ key: CREATE_ORDER, inProcess: false })
      );
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: CREATE_ORDER,
          hasError: true,
          errorMessage: `${e.response.status} ${e.response.data}`,
        })
      );
      notifications.show({
        title: "Error",
        message: `${e.response.status} ${e.response.data}`,
        color: "red",
      });
      console.log(e);
    }
  }
}

export function* orderSell() {
  while (true) {
    const { id } = yield take(ORDER_SELL);

    const processName = `${ORDER_SELL}-${id}`;

    try {
      yield put(
        processesSlice.actions.update({ key: processName, inProcess: true })
      );

      const result = yield call(axios, {
        url: "/api/order-sell",
        method: "post",
        data: { id },
      });

      // notifications.show({
      //   title: "Profit 💰",
      //   message: result.data.profit,
      //   color: result.data.profit > 0 ? "green" : "red",
      // });

      yield put(
        processesSlice.actions.update({ key: processName, inProcess: false })
      );
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: processName,
          hasError: true,
          errorMessage: `${e.response.status} ${e.response.data}`,
        })
      );
      notifications.show({
        title: "Error",
        message: `${e.response.status} ${e.response.data}`,
        color: "red",
      });
      console.log(e);
    }
  }
}

export function* orderBuy() {
  while (true) {
    const { id } = yield take(ORDER_BUY);

    const processName = `${ORDER_BUY}-${id}`;

    try {
      yield put(
        processesSlice.actions.update({ key: processName, inProcess: true })
      );

      const result = yield call(axios, {
        url: "/api/order-buy",
        method: "post",
        data: { id },
      });

      yield put(
        processesSlice.actions.update({ key: processName, inProcess: false })
      );
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: processName,
          hasError: true,
          errorMessage: `${e.response.status} ${e.response.data}`,
        })
      );
      notifications.show({
        title: "Error",
        message: `${e.response.status} ${e.response.data}`,
        color: "red",
      });
      console.log(e);
    }
  }
}

export function* orderStop() {
  while (true) {
    const { id } = yield take(ORDER_STOP);

    const processName = `${ORDER_STOP}-${id}`;

    try {
      yield put(
        processesSlice.actions.update({ key: processName, inProcess: true })
      );

      const result = yield call(axios, {
        url: "/api/stop-order",
        method: "post",
        data: { id },
      });

      yield put(
        processesSlice.actions.update({ key: processName, inProcess: false })
      );
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: processName,
          hasError: true,
          errorMessage: `${e.response.status} ${e.response.data}`,
        })
      );
      notifications.show({
        title: "Error",
        message: `${e.response.status} ${e.response.data}`,
        color: "red",
      });
      console.log(e);
    }
  }
}

export function* orderDelete() {
  while (true) {
    const { id } = yield take(ORDER_DELETE);

    const processName = `${ORDER_DELETE}-${id}`;

    try {
      yield put(
        processesSlice.actions.update({ key: processName, inProcess: true })
      );

      const result = yield call(axios, {
        url: "/api/delete-order",
        method: "post",
        data: { id },
      });

      yield put(
        processesSlice.actions.update({ key: processName, inProcess: false })
      );
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: processName,
          hasError: true,
          errorMessage: `${e.response.status} ${e.response.data}`,
        })
      );
      notifications.show({
        title: "Error",
        message: `${e.response.status} ${e.response.data}`,
        color: "red",
      });
      console.log(e);
    }
  }
}

export function* orderResume() {
  while (true) {
    const { id } = yield take(ORDER_RESUME);

    const processName = `${ORDER_RESUME}-${id}`;

    try {
      yield put(
        processesSlice.actions.update({ key: processName, inProcess: true })
      );

      const result = yield call(axios, {
        url: "/api/resume-order",
        method: "post",
        data: { id },
      });

      yield put(
        processesSlice.actions.update({ key: processName, inProcess: false })
      );
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: processName,
          hasError: true,
          errorMessage: `${e.response.status} ${e.response.data}`,
        })
      );
      notifications.show({
        title: "Error",
        message: `${e.response.status} ${e.response.data}`,
        color: "red",
      });
      console.log(e);
    }
  }
}

export function* orderClose() {
  while (true) {
    const { id } = yield take(ORDER_CLOSE);

    const processName = `${ORDER_CLOSE}-${id}`;

    try {
      yield put(
        processesSlice.actions.update({ key: processName, inProcess: true })
      );

      const result = yield call(axios, {
        url: "/api/close-order",
        method: "post",
        data: { id },
      });

      yield put(
        processesSlice.actions.update({ key: processName, inProcess: false })
      );
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: processName,
          hasError: true,
          errorMessage: `${e.response.status} ${e.response.data}`,
        })
      );
      notifications.show({
        title: "Error",
        message: `${e.response.status} ${e.response.data}`,
        color: "red",
      });
      console.log(e);
    }
  }
}

export function* updateOrder() {
  while (true) {
    const { id, updateOb, onSuccess } = yield take(UPDATE_ORDER);

    const processName = `${UPDATE_ORDER}-${id}`;

    try {
      yield put(
        processesSlice.actions.update({ key: processName, inProcess: true })
      );

      const result = yield call(axios, {
        url: "/api/update-order",
        method: "post",
        data: {
          id,
          updateOb,
        },
      });

      notifications.show({
        title: "Order is updated",
        color: "blue",
      });

      yield put(
        processesSlice.actions.update({ key: processName, inProcess: false })
      );

      onSuccess && onSuccess();
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: processName,
          hasError: true,
          errorMessage: `${e.response.status} ${e.response.data}`,
        })
      );
      notifications.show({
        title: "Error",
        message: `${e.response.status} ${e.response.data}`,
        color: "red",
      });
      console.log(e);
    }
  }
}

// watching functions

function* fetchOrders({ isFake, delayTime }) {
  if (delayTime) {
    yield delay(delayTime);
  }

  const collection = Mongo.client.db(getDb(isFake)).collection("orders");

  const result = yield collection.find(
    { status: { $ne: "closed" } },
    { sort: { _id: -1 } }
  );

  yield put(
    ordersSlice.actions.update({ [getReduxKey(isFake)]: result || [] })
  );

  return collection;
}

function* initWatchOrders({ isFake } = {}) {
  try {
    if (!Mongo.client) {
      yield call(Mongo.connect);
    }

    const collection = yield call(fetchOrders, { isFake });

    const generator = collection.watch();

    while (true) {
      const { value: change } = yield generator.next();

      if (!change) {
        continue;
      }

      if (change.operationType === "delete") {
        const state = yield select();

        const data = state.orders[getReduxKey(isFake)].filter((doc) => {
          return !doc._id.equals(change.documentKey._id);
        });

        yield put(ordersSlice.actions.update({ [getReduxKey(isFake)]: data }));
      } else if (change.operationType === "insert") {
        const state = yield select();

        const data = [
          change.fullDocument,
          ...state.orders[getReduxKey(isFake)],
        ];

        yield put(ordersSlice.actions.update({ [getReduxKey(isFake)]: data }));
      } else if (change.operationType === "update") {
        const state = yield select();

        const data = state.orders[getReduxKey(isFake)].map((doc) =>
          doc._id.equals(change.documentKey._id) ? change.fullDocument : doc
        );

        yield put(ordersSlice.actions.update({ [getReduxKey(isFake)]: data }));
      }

      if (process.env.NODE_ENV === "development") {
        // console.log(change);
      }
    }
  } catch (e) {
    console.log(e);
    yield put(watchOrders());
  } finally {
    if (yield cancelled()) {
      console.log("watchOrders is cancelled!");
    }
  }
}

export function* manageOrders() {
  let lastTask;
  while (true) {
    yield take([WATCH_ORDERS, "auth/setIsAuthorized"]);

    if (lastTask) {
      yield cancel(lastTask);
    }

    const state = yield select();
    if (!state.auth.isAuthorized) continue;

    lastTask = yield fork(initWatchOrders);
  }
}

export function* manageFakeOrders() {
  // !deprecated
  // let lastTask;
  // while (true) {
  //   yield take([WATCH_ORDERS, "auth/setIsAuthorized"]);
  //
  //   if (lastTask) {
  //     yield cancel(lastTask);
  //   }
  //
  //   const state = yield select();
  //   if (!state.auth.isAuthorized) continue;
  //
  //   lastTask = yield fork(initWatchOrders, { isFake: true });
  // }
}
