import {
  take,
  fork,
  cancel,
  call,
  cancelled,
  put,
  delay,
  select,
  takeLatest,
} from "redux-saga/effects";

import Mongo from "../../sevices/mongo";

import {
  WATCH_STARRED,
  ADD_STARRED,
  DELETE_STARRED,
  RESET_ALL_STARRED,
  TOGGLE_STARRED,
  watchStarred,
  starredSlice,
} from "./redux";

const getDb = (isFake) => (isFake ? Mongo.dbs.test : Mongo.dbs.main);

// crud functions

export function* watchToggleStarred() {
  yield takeLatest(TOGGLE_STARRED, toggle);
}
function* toggle(action) {
  let { symbol, platform, value } = action;
  if (value) {
    yield remove({ symbol });
  } else {
    yield add({ symbol, platform });
  }
}

export function* watchAddStarred() {
  yield takeLatest(ADD_STARRED, add);
}
function* add(action, isFake) {
  try {
    let { symbol, platform } = action;
    symbol = symbol.replace("USDT", "");
    yield put(
      starredSlice.actions.add({
        symbol: symbol,
        platform: platform,
        created: new Date(),
      })
    );
    const collection = Mongo.client.db(getDb(isFake)).collection("starred");
    yield collection.updateOne(
      { userId: 136434928 },
      {
        $set: {
          [`tokens.${symbol}`]: {
            symbol: symbol,
            platform: platform,
            created: new Date(),
          },
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
}

export function* watchDeleteStarred() {
  yield takeLatest(DELETE_STARRED, remove);
}
function* remove(action, isFake) {
  try {
    let { symbol } = action;
    symbol = symbol.replace("USDT", "");
    yield put(starredSlice.actions.delete({ symbol: symbol }));
    const collection = Mongo.client.db(getDb(isFake)).collection("starred");
    yield collection.updateOne(
      { userId: 136434928 },
      { $unset: { [`tokens.${symbol}`]: 1 } }
    );
  } catch (e) {
    console.log(e);
  }
}

export function* watchResetAllStarred() {
  yield takeLatest(RESET_ALL_STARRED, resetAll);
}
function* resetAll(action, isFake) {
  try {
    const collection = Mongo.client.db(getDb(isFake)).collection("starred");
    yield put(starredSlice.actions.set({}));
    yield collection.updateOne({ userId: 136434928 }, { $set: { tokens: {} } });
  } catch (e) {
    console.log(e);
  }
}

// watching functions

function* fetchStarred({ isFake, delayTime }) {
  if (delayTime) {
    yield delay(delayTime);
  }

  const collection = Mongo.client.db(getDb(isFake)).collection("starred");

  const result = yield collection.findOne({ userId: 136434928 });

  yield put(starredSlice.actions.set(result || {}));

  return collection;
}

function* initWatchStarred({ isFake } = {}) {
  try {
    if (!Mongo.client) {
      yield call(Mongo.connect);
    }

    const collection = yield call(fetchStarred, { isFake });

    const generator = collection.watch();

    while (true) {
      const { value: change } = yield generator.next();

      if (!change) {
        continue;
      }

      if (["insert", "update", "delete"].includes(change.operationType)) {
        if (change.fullDocument.userId === 136434928)
          yield put(starredSlice.actions.set(change.fullDocument));
      }

      if (process.env.NODE_ENV === "development") {
        // console.log(change);
      }
    }
  } catch (e) {
    console.log(e);
    if (![403].includes(e.statusCode)) yield put(watchStarred());
  } finally {
    if (yield cancelled()) {
      console.log("watchStarred is cancelled!");
    }
  }
}

export function* manageStarred() {
  let lastTask;
  while (true) {
    yield take([WATCH_STARRED, "auth/setIsAuthorized"]);

    if (lastTask) {
      yield cancel(lastTask);
    }

    const state = yield select();
    if (!state.auth.isAuthorized) continue;

    lastTask = yield fork(initWatchStarred);
  }
}
