import { IconPlus, IconX } from "@tabler/icons-react";
import {
  Button,
  CopyButton,
  Grid,
  Input,
  NumberInput,
  SegmentedControl,
  Switch,
} from "@mantine/core";
import { useSelector } from "react-redux";

function SellOnPercent({ symbol, strategy, updateStrategy, removeStrategy }) {
  let currentPrice = useSelector((state) => {
    return state.currentPrice[`${symbol.replace("USDT", "")}USDT`];
  });
  currentPrice = currentPrice || 0;

  return (
    <Grid>
      <Grid.Col lg={3} style={{ display: "flex", alignItems: "center" }}>
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          onClick={() => removeStrategy(strategy)}
        >
          Sell On (%)
        </Button>
      </Grid.Col>
      <Grid.Col lg={9}>
        <Grid>
          <Grid.Col lg={12}>
            <Grid>
              <Grid.Col lg={4}>
                <NumberInput
                  label="Down %"
                  placeholder="Down %"
                  precision={1}
                  step={1}
                  value={strategy.params.down}
                  onChange={(value) =>
                    updateStrategy({
                      ...strategy,
                      params: { ...strategy.params, down: value },
                    })
                  }
                />
              </Grid.Col>
              <Grid.Col lg={4}>
                <NumberInput
                  label="Up %"
                  placeholder="Up %"
                  precision={1}
                  step={1}
                  value={strategy.params.up}
                  onChange={(value) =>
                    updateStrategy({
                      ...strategy,
                      params: { ...strategy.params, up: value },
                    })
                  }
                />
              </Grid.Col>
              <Grid.Col
                lg={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  paddingBottom: "1rem",
                }}
              >
                <Switch
                  label={<b>AND</b>}
                  checked={strategy.params.and}
                  onClick={(e) =>
                    updateStrategy({
                      ...strategy,
                      params: { ...strategy.params, and: e.target.checked },
                    })
                  }
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col lg={12}>
            <Grid>
              <Grid.Col lg={6}>
                <SegmentedControl
                  value={strategy.params.base}
                  onChange={(value) =>
                    updateStrategy({
                      ...strategy,
                      params: { ...strategy.params, base: value },
                    })
                  }
                  data={[
                    { value: "buy", label: "buy" },
                    { value: "ceil", label: "ceil" },
                    { value: "bottom", label: "bottom" },
                    { value: "custom", label: "custom" },
                  ]}
                />
              </Grid.Col>
              <Grid.Col lg={3}>
                <Input
                  label="Custom ($)"
                  placeholder="Custom ($)"
                  value={strategy.params.customPrice}
                  disabled={strategy.params.base !== "custom"}
                  onChange={(e) =>
                    updateStrategy({
                      ...strategy,
                      params: {
                        ...strategy.params,
                        customPrice: e.target.value,
                      },
                    })
                  }
                />
              </Grid.Col>
              <Grid.Col lg={3}>
                <CopyButton value={currentPrice}>
                  {({ copied, copy }) => (
                    <Button variant="white" color="dark" onClick={copy}>
                      {currentPrice}$
                    </Button>
                  )}
                </CopyButton>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

SellOnPercent.type = "sell";
SellOnPercent.color = "teal";
SellOnPercent.model = {
  name: "sell_on_percent",
  title: "Sell on percent",
  params: { down: "", up: "", base: "buy", customPrice: "" },
};
SellOnPercent.paramsTitle = (strategy) => {
  let { up, down, base } = strategy.params;

  if (up !== "" && Number(up) >= 0) up = `+${up}`;
  if (down !== "" && Number(down) >= 0) down = `+${down}`;

  if (down !== "" && up !== "") {
    return `${down}% - ${up}%`;
  } else if (strategy.params.down !== "") {
    return `<= ${down}%, ${base || "buy"}`;
  } else if (up !== "") {
    return `>= ${up}%, ${base || "buy"}`;
  } else {
    return `-`;
  }
};

SellOnPercent.Button = (props) => {
  return (
    <Button variant="default" color="teal" leftIcon={<IconPlus />} {...props}>
      Sell On (%)
    </Button>
  );
};

export default SellOnPercent;
