import { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Box,
  Center,
  SegmentedControl,
  Group,
  Switch,
} from "@mantine/core";
import Mongo from "../common/sevices/mongo";
import {
  AreaChart,
  Area,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  ComposedChart,
  Legend,
  Scatter,
} from "recharts";
import { getAverage } from "../common/helpers";
import { current } from "@reduxjs/toolkit";

const intervals = ["1w", "1d", "4h"];
// const intervals = ["1w"];

export default function TI() {
  // show settings
  const [interval, setInterval] = useState("1d");
  const [shift, setShift] = useState("0");
  const [showMonths, setShowMonths] = useState("3");
  const [switch1w, setSwitch1w] = useState(true);
  const [switch1d, setSwitch1d] = useState(true);
  const [switchOrders, setSwitchOrders] = useState(false);
  const [switchDots, setSwitchDots] = useState(false);
  const [switchBorder, setSwitchBorder] = useState(false);
  // data
  const [ti, setTi] = useState(null);
  const [rawTi, setRawTi] = useState(null);
  const [orders, setOrders] = useState([]);

  useEffect(function getTrendIndexes() {
    const get = async () => {
      try {
        if (!Mongo.client) await Mongo.connect();

        let map = {};

        for (let i of intervals) {
          const collection = Mongo.client
            .db(Mongo.dbs.main)
            .collection(`trendIndex_${i}`);
          let raw = await collection.find({}, { sort: { time: 1 } });

          let normalized = raw.map(
            ({ time, gisFisStats: { trendIndex } }, index) => {
              let day = new Date(time).toLocaleString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              });
              let month = new Date(time).toLocaleString("en-GB", {
                month: "short",
                year: "numeric",
              });

              return {
                interval: i,
                trendIndex,
                time: new Date(time),
                day,
                month,
              };
            }
          );

          raw.sort(
            (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
          );

          // if (i === "1d")
          //   raw.forEach((item) => {
          //     console.log(new Date(item.time).toLocaleString());
          //   });

          map[i] = normalized;
        }

        for (let currentInterval of intervals) {
          // if currentInterval = 1d, then applyIntervals = [1w,1d,4h] => [1w]
          let applyIntervals = intervals.slice(
            0,
            intervals.indexOf(currentInterval)
          );

          for (let applyInterval of applyIntervals) {
            for (let item of map[currentInterval]) {
              let applyItem = map[applyInterval]
                .slice()
                .reverse()
                .find(
                  (applyItem) => applyItem.time.getTime() <= item.time.getTime()
                );
              item[`trendIndex${applyInterval}`] = applyItem?.trendIndex;
            }
          }
        }

        setRawTi(map);
      } catch (e) {
        console.log(e);
        setRawTi(null);
      }
    };

    get();
  }, []);

  useEffect(
    function getOrders() {
      if (!switchOrders) return;

      const get = async () => {
        try {
          if (!Mongo.client) await Mongo.connect();
          const collection = Mongo.client
            .db(Mongo.dbs.chartTest)
            .collection("orders");
          let orders = await collection.find();
          // orders = orders.filter(
          //   (order) =>
          //     order.alert?.alert?.minor?.growth >= 60 &&
          //     order?.buy?.strategy?.payload?.ti?.["1d"]?.last?.gisFisStats
          //       .trendIndex -
          //       order?.buy?.strategy?.payload?.ti?.["1d"]?.prev?.gisFisStats
          //         .trendIndex >=
          //       10 &&
          //     order?.buy?.strategy?.payload?.ti?.["4h"]?.last?.gisFisStats
          //       .trendIndex -
          //       order?.buy?.strategy?.payload?.ti?.["4h"]?.prev?.gisFisStats
          //         .trendIndex >=
          //       3
          //   // && order?.buy?.strategy?.payload?.ti?.["1d"]?.last?.gisFisStats
          //   // .trendIndex > 0
          // );
          // console.log(orders);
          setOrders(orders);
        } catch (e) {
          console.log(e);
          setOrders(null);
        }
      };

      get();
    },
    [switchOrders]
  );

  const profit = useMemo(() => {
    if (!orders) return;

    return orders.reduce(
      (sum, order) => (order.stat?.profit ? sum + order.stat?.profit : sum),
      0
    );
  }, [orders]);

  useEffect(
    function setAdditionalData() {
      if (!rawTi || !orders) return;

      for (let interval of intervals) {
        let items = rawTi[interval];
        // if (interval === "1d")
        //   for (let ob of items) console.log(new Date(ob.time).toLocaleString());
        if (!items) continue;
        items = JSON.parse(JSON.stringify(items));

        for (let ob of items) {
          ob.profit = 0;
          ob.orders = [];
        }

        for (let order of orders) {
          let ob = [...items].reverse().find((applyItem) => {
            return (
              new Date(applyItem.time).getTime() <=
              new Date(order.buy.time).getTime()
            );
          });
          if (!ob) continue;
          // console.log(ob.profit, order.stat.profit);
          if (order?.stat?.profit) {
            ob.profit += order.stat?.profitPercent;
            // ob.profit += order.stat.profit;
            ob.orders.push(order);
            ob.orders.sort(
              (a, b) => b.stat.profitPercent - a.stat.profitPercent
            );
          }
          if (ob.profit >= 0) {
            delete ob.red;
            ob.green = ob.trendIndex + 10;
            ob.z = Math.abs(ob.profit);
          } else {
            delete ob.green;
            ob.red = ob.trendIndex + 10;
            ob.z = Math.abs(ob.profit);
          }
        }

        setTi((current) => ({ ...current, [interval]: items }));
      }
    },
    [rawTi, orders]
  );

  const cut = useMemo(() => {
    if (!ti?.[interval]) return;
    let cut = ti[interval];

    let normalizedShift;
    if (interval === "1w") {
      normalizedShift = Number(shift) * 4;
    } else if (interval === "1d") {
      normalizedShift = Number(shift) * 30;
    } else if (interval === "4h") {
      normalizedShift = Number(shift) * 30 * 6;
    }
    let normalizedMonths;
    if (interval === "1w") {
      normalizedMonths = Number(showMonths) * 4;
    } else if (interval === "1d") {
      normalizedMonths = Number(showMonths) * 30;
    } else if (interval === "4h") {
      normalizedMonths = Number(showMonths) * 30 * 6;
    }

    if (Number(normalizedShift) > 0) {
      cut = cut.slice(0, -normalizedShift);
    }
    cut = cut.slice(-normalizedMonths);

    return cut;
  }, [ti, interval, orders, shift, showMonths]);

  const { avg, off, off1d, off1w, green, red } = useMemo(() => {
    if (!cut) return {};

    const gradientOffset = ({ prop }) => {
      const dataMax = Math.max(...cut.map((i) => i[prop]).filter(Boolean));
      const dataMin = Math.min(...cut.map((i) => i[prop]).filter(Boolean));

      if (dataMax <= 0) {
        return 0;
      }
      if (dataMin >= 0) {
        return 1;
      }

      return dataMax / (dataMax - dataMin);
    };

    const off = gradientOffset({ prop: "trendIndex" });
    const off1d = gradientOffset({ prop: "trendIndex1d" });
    const off1w = gradientOffset({ prop: "trendIndex1w" });

    return {
      avg: getAverage(cut?.map((ob) => ob.trendIndex)).toFixed(0),
      off: off,
      off1d: off1d,
      off1w: off1w,
    };
  }, [cut, shift, showMonths]);

  const showDots = useMemo(() => {
    if (!switchDots) return false;
    if (interval === "1w") {
      return showMonths < 48;
    } else if (interval === "1d") {
      return showMonths < 24;
    } else if (interval === "4h") {
      return showMonths < 12;
    }
  }, [interval, showMonths, switchDots]);

  if (!cut) return null;

  return (
    <Grid>
      <Grid.Col span={12}>
        <Center>
          <Group>
            <SegmentedControl
              value={interval}
              onChange={setInterval}
              data={[
                { label: "interval", value: "interval", disabled: true },
                { label: "1w", value: "1w" },
                { label: "1d", value: "1d" },
                { label: "4h", value: "4h" },
              ]}
            />
            <SegmentedControl
              value={shift}
              onChange={setShift}
              data={[
                {
                  label: "shift",
                  value: "shift",
                  disabled: true,
                },
                { label: "0", value: "0" },
                { label: "-1m", value: "1" },
                { label: "-2m", value: "2" },
                { label: "-3m", value: "3" },
                { label: "-6m", value: "6" },
                { label: "-9m", value: "9" },
                { label: "-1y", value: "12" },
              ]}
            />
            <SegmentedControl
              value={showMonths}
              onChange={setShowMonths}
              data={[
                { label: "show", value: "show", disabled: true },
                { label: "1m", value: "1" },
                { label: "2m", value: "2" },
                { label: "3m", value: "3" },
                { label: "6m", value: "6" },
                { label: "9m", value: "9" },
                { label: "1y", value: "12" },
                { label: "2y", value: "24" },
                { label: "3y", value: "36" },
                { label: "4y", value: "48" },
              ]}
            />
            <Switch
              size="md"
              label="1w"
              color="green"
              checked={switch1w}
              onChange={(event) => setSwitch1w(event.currentTarget.checked)}
            />
            <Switch
              size="md"
              label="1d"
              color="green"
              checked={switch1d}
              onChange={(event) => setSwitch1d(event.currentTarget.checked)}
            />
            <Switch
              size="md"
              label="orders"
              color="green"
              checked={switchOrders}
              onChange={(event) => setSwitchOrders(event.currentTarget.checked)}
            />
            <Switch
              size="md"
              label="dots"
              color="green"
              checked={switchDots}
              onChange={(event) => setSwitchDots(event.currentTarget.checked)}
            />
            <Switch
              size="md"
              label="border"
              color="green"
              checked={switchBorder}
              onChange={(event) => setSwitchBorder(event.currentTarget.checked)}
            />
          </Group>
        </Center>
      </Grid.Col>
      {switchOrders && (
        <Grid.Col span={12}>
          <Center>
            <b>Profit:</b> {profit.toFixed(0)}$
          </Center>
        </Grid.Col>
      )}
      <Grid.Col span={12}>
        <Center>
          <ComposedChart
            width={1600}
            height={800}
            data={cut}
            margin={{
              top: 20,
              right: 80,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0].payload; // Данные текущей точки
                  return (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <p>
                        <strong>{data.day}</strong>
                      </p>
                      <p>
                        <strong>({data.interval}):</strong> {data.trendIndex}{" "}
                        {data.trendIndex1d !== undefined && switch1d && (
                          <span>
                            <strong>(1d):</strong> {data.trendIndex1d}
                          </span>
                        )}{" "}
                        {data.trendIndex1w !== undefined && switch1w && (
                          <span>
                            <strong>(1w):</strong> {data.trendIndex1w}
                          </span>
                        )}
                      </p>

                      {switchOrders && (
                        <>
                          <p>
                            <strong>orders:</strong> {data?.orders?.length}
                          </p>

                          <p>
                            {data?.orders?.map((order, i) => {
                              return (
                                <div
                                  key={order._id}
                                  style={{
                                    fontSize: "12px",
                                    color:
                                      order.stat?.profitPercent >= 0
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {order.symbol}{" "}
                                  {order.stat?.profitPercent >= 0 ? "+" : ""}
                                  {order.stat?.profitPercent}%{" "}
                                  <i>
                                    ({new Date(order.buy.time).toLocaleString()}
                                    )
                                  </i>
                                </div>
                              );
                            })}
                          </p>
                        </>
                      )}
                    </div>
                  );
                }
                return null;
              }}
            />
            <Legend />
            <XAxis dataKey="day" />
            <YAxis domain={[-100, 100]} />
            <ZAxis
              type="number"
              dataKey="z"
              range={[1, 1000]}
              name="score"
              unit="km"
            />
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset={off} stopColor="#21881c" stopOpacity={1} />
                <stop offset={off} stopColor="#ff241e" stopOpacity={1} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="splitColor1d" x1="0" y1="0" x2="0" y2="1">
                <stop offset={off1d} stopColor="#3f9438" stopOpacity={1} />
                <stop offset={off1d} stopColor="#ff423b" stopOpacity={1} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="splitColor1w" x1="0" y1="0" x2="0" y2="1">
                <stop offset={off1w} stopColor="#7ab273" stopOpacity={1} />
                <stop offset={off1w} stopColor="#ff7c76" stopOpacity={1} />
              </linearGradient>
            </defs>
            {switch1w && (
              <Area
                type="monotone"
                dataKey="trendIndex1w"
                stroke={switchBorder ? "#f79502" : null}
                strokeWidth={2}
                fill="url(#splitColor1w)"
                isAnimationActive={false}
                // dot={showMonths <= 6 ? { stroke: "1px", fill: "blue" } : false}
              />
            )}
            {switch1d && (
              <Area
                type="monotone"
                dataKey="trendIndex1d"
                stroke={switchBorder ? "#5552c7" : null}
                fill="url(#splitColor1d)"
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
                // dot={showMonths <= 6 ? { stroke: "1px", fill: "blue" } : false}
              />
            )}
            <Area
              type="monotone"
              dataKey="trendIndex"
              stroke={switchBorder ? "black" : null}
              strokeWidth={2}
              fill="url(#splitColor)"
              // dot={false}
              isAnimationActive={false}
              dot={showDots ? { stroke: "2px", fill: "black" } : false}
            />
            {switchOrders && (
              <>
                <Scatter
                  name="red"
                  dataKey="red"
                  fill="red"
                  shape="cross"
                  size={10}
                  stroke="white"
                />
                <Scatter
                  name="green"
                  dataKey="green"
                  fill="green"
                  shape="diamond"
                  size={90}
                  stroke="white"
                />
              </>
            )}
            {/*<ReferenceLine y={5} label={""} stroke="green" strokeWidth={3} />*/}
            {/*<ReferenceLine y={-5} label={""} stroke="red" strokeWidth={3} />*/}
            {["1d", "4h"].includes(interval) &&
              cut
                .filter(({ day }) => /^1\s+/.test(day))
                .map(({ day }) => (
                  <ReferenceLine
                    key={day}
                    x={day}
                    // label={day}
                    stroke={"rgba(128, 128, 128)"}
                    strokeWidth={0.5}
                  >
                    <Label value={day} offset={-20} position="top" />
                  </ReferenceLine>
                ))}
          </ComposedChart>
        </Center>
      </Grid.Col>
      {/*<Grid.Col span={12}>*/}
      {/*  <Center>*/}
      {/*    <AreaChart*/}
      {/*        width={1500}*/}
      {/*        height={500}*/}
      {/*        data={cut}*/}
      {/*        margin={{*/}
      {/*          top: 10,*/}
      {/*          right: 30,*/}
      {/*          left: 0,*/}
      {/*          bottom: 0,*/}
      {/*        }}*/}
      {/*    >*/}
      {/*      <CartesianGrid stroke="#f5f5f5" />*/}
      {/*      <XAxis dataKey="day" />*/}
      {/*      <YAxis domain={[-100, 100]} />*/}
      {/*      <Tooltip />*/}
      {/*      <defs>*/}
      {/*        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">*/}
      {/*          <stop offset={off} stopColor="green" stopOpacity={1} />*/}
      {/*          <stop offset={off} stopColor="red" stopOpacity={1} />*/}
      {/*        </linearGradient>*/}
      {/*      </defs>*/}
      {/*      <Area*/}
      {/*          type="monotone"*/}
      {/*          dataKey="trendIndex"*/}
      {/*          stroke="blue"*/}
      {/*          fill="url(#splitColor)"*/}
      {/*          dot={showMonths <= 6 ? { stroke: "1px", fill: "blue" } : false}*/}
      {/*      />*/}
      {/*      /!*<ReferenceLine y={5} label={""} stroke="green" strokeWidth={3} />*!/*/}
      {/*      /!*<ReferenceLine y={-5} label={""} stroke="red" strokeWidth={3} />*!/*/}
      {/*      {cut*/}
      {/*          .filter(({ day }) => /^1\s+/.test(day))*/}
      {/*          .map(({ day }) => (*/}
      {/*              <ReferenceLine*/}
      {/*                  key={day}*/}
      {/*                  x={day}*/}
      {/*                  // label={day}*/}
      {/*                  stroke={"rgba(128, 128, 128)"}*/}
      {/*                  strokeWidth={0.5}*/}
      {/*              >*/}
      {/*                <Label value={day} offset={-20} position="top" />*/}
      {/*              </ReferenceLine>*/}
      {/*          ))}*/}
      {/*    </AreaChart>*/}
      {/*  </Center>*/}
      {/*</Grid.Col>*/}
      <Grid.Col span={12}>
        <Center>
          <LineChart width={1500} height={500} data={cut}>
            <CartesianGrid stroke="#f5f5f5" />
            <Line
              type="monotone"
              dataKey="trendIndex"
              stroke="blue"
              name="trendIndex"
              strokeWidth={3}
              dot={showMonths <= 6}
              // dot={{ stroke: "blue", strokeWidth: 1 }}
              // fill="#82ca9d"
              // label={<CustomizedLabel />}
            />
            <ReferenceLine y={0} label={""} stroke="gray" strokeWidth={3} />
            {cut
              .filter(({ day }) => /^1\s+/.test(day))
              .map(({ day }) => (
                <ReferenceLine
                  key={day}
                  x={day}
                  // label={day}
                  stroke={"rgba(128, 128, 128)"}
                  strokeWidth={0.5}
                >
                  <Label value={day} offset={-20} position="top" />
                </ReferenceLine>
              ))}
            <CartesianGrid strokeDasharray="0.5 0.5" />
            <XAxis dataKey="day" />
            <YAxis domain={[-100, 100]} />
            <Tooltip />
          </LineChart>
        </Center>
      </Grid.Col>
    </Grid>
  );
}
