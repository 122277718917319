import { Box, Text, Highlight } from "@mantine/core";
import { getSpotTradingPageUrl } from "../common/helpers";

import { TH } from "../common/components/Table";

export const SymbolTH = () => {
  return <TH title="💎" />;
  // return <TH title="sml" />;
};

export const Symbol = ({ symbol, platform, ll }) => {
  let color = "dark.9";
  let llEmojie = "";
  if (ll <= 3) {
    color = "teal.9";
    llEmojie = "❇️";
  } else if (ll <= 30) {
    color = "orange.9";
    llEmojie = "⭐";
  } else if (ll <= 90) {
    // color = "indigo.9";
    // llEmojie = "🔹"; // "🔥";
  }
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text size="sm" span={true}>
        {llEmojie}
      </Text>
      <Text
        size="sm"
        weight={700}
        align="center"
        color={color}
        span={true}
        ml="0.2rem"
      >
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={`https://www.tradingview.com/chart/?symbol=${platform.toUpperCase()}:${symbol}`}
          target="_blank"
        >
          {symbol.replace("USDT", "")}
          {/*{!!color ? (*/}
          {/*  <Highlight*/}
          {/*    highlight={symbol.replace("USDT", "")}*/}
          {/*    highlightColor={color}*/}
          {/*    color="white"*/}
          {/*  >*/}
          {/*    {symbol.replace("USDT", "")}*/}
          {/*  </Highlight>*/}
          {/*) : (*/}
          {/*  symbol.replace("USDT", "")*/}
          {/*)}*/}
        </a>
      </Text>
    </Box>
  );
};

export const Platform = ({ symbol, platform }) => {
  return (
    <Text
      size="sm"
      weight={700}
      align="center"
      // color={
      //   platform === "mexc"
      //     ? "indigo.9"
      //     : platform === "binance"
      //     ? "orange.8"
      //     : "dark.9"
      // }
    >
      <a
        style={{ textDecoration: "none", color: "inherit" }}
        href={getSpotTradingPageUrl(symbol, platform)}
        target="_blank"
      >
        {platform}
      </a>
    </Text>
  );
};
