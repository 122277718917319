import { Fragment } from "react";
import { Grid, Button, Modal, Group, Divider, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import uniqid from "uniqid";

import { Strategies } from "./config";
import Unknown from "./Unknown";

export { StrategyPickerBoard } from "./Board";

export function StrategyPickerModal({
  symbol,
  show,
  buyStrategies,
  sellStrategies,
  setBuyStrategies,
  setSellStrategies,
  repeatStrategies,
  setRepeatStrategies,
  onClose,
  onSubmit,
  process,
  disableBuyStrategies,
  disableRepeatStrategies,
}) {
  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  const addStrategy = (setStrategies, model) => {
    setStrategies((strategies) => [...strategies, { id: uniqid(), ...model }]);
  };

  const updateStrategy = (setStrategies, updatedStrategy) => {
    setStrategies((strategies) =>
      strategies.map((strategy) =>
        strategy.id === updatedStrategy.id ? { ...updatedStrategy } : strategy
      )
    );
  };

  const removeStrategy = (setStrategies, removeStrategy) => {
    setStrategies((strategies) =>
      strategies.filter((strategy) => strategy.id !== removeStrategy.id)
    );
  };

  return (
    <Modal
      opened={show}
      onClose={onClose}
      centered={true}
      withCloseButton={false}
      size={isSmallerThanSmall ? "100%" : isSmallerThanLarge ? "75%" : "50%"}
      transitionProps={{ duration: 0 }}
      lockScroll={false}
    >
      <Grid>
        {!disableBuyStrategies && (
          <>
            {/*Buy Strategies Buttons*/}
            <Grid.Col span={12}>
              <Group>
                {Object.values(Strategies).map(({ type, model, Button }) => {
                  if (type !== "buy") return null;

                  return (
                    <Button
                      key={model.name}
                      onClick={() => addStrategy(setBuyStrategies, model)}
                      disabled={disableBuyStrategies}
                    />
                  );
                })}
              </Group>
            </Grid.Col>
            {/*Buy Strategies Divider*/}
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            {/*Buy Strategies Components*/}
            {!buyStrategies.length ? (
              <Grid.Col span={12}>
                <Text fw={700} align="center" my="2rem">
                  No buy strategies
                </Text>
              </Grid.Col>
            ) : (
              buyStrategies.map((strategy) => {
                const Strategy = Strategies[strategy.name]?.component;

                if (!Strategy) {
                  return (
                    <Unknown
                      strategy={strategy}
                      removeStrategy={(strategy) =>
                        removeStrategy(setSellStrategies, strategy)
                      }
                    />
                  );
                }

                return (
                  <Fragment key={strategy.id}>
                    <Grid.Col span={12}>
                      <Strategy
                        symbol={symbol}
                        strategy={strategy}
                        updateStrategy={(updatedStrategy) =>
                          updateStrategy(setBuyStrategies, updatedStrategy)
                        }
                        removeStrategy={(strategy) =>
                          removeStrategy(setBuyStrategies, strategy)
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Divider />
                    </Grid.Col>
                  </Fragment>
                );
              })
            )}
          </>
        )}
        {/*Sell Strategies Buttons*/}
        <Grid.Col span={12}>
          <Group>
            {Object.values(Strategies).map(({ type, model, Button }) => {
              if (type !== "sell") return null;

              return (
                <Button
                  key={model.name}
                  onClick={() => addStrategy(setSellStrategies, model)}
                />
              );
            })}
          </Group>
        </Grid.Col>
        {/*Sell Strategies Divider*/}
        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>
        {/*Sell Strategies Components*/}
        {!sellStrategies.length ? (
          <Grid.Col span={12}>
            <Text fw={700} align="center" my="2rem">
              No sell strategies
            </Text>
          </Grid.Col>
        ) : (
          sellStrategies.map((strategy) => {
            const Strategy = Strategies[strategy.name]?.component;

            if (!Strategy) {
              return (
                <Unknown
                  strategy={strategy}
                  removeStrategy={(strategy) =>
                    removeStrategy(setSellStrategies, strategy)
                  }
                />
              );
            }

            return (
              <Fragment key={strategy.id}>
                <Grid.Col span={12}>
                  <Strategy
                    symbol={symbol}
                    strategy={strategy}
                    updateStrategy={(updatedStrategy) =>
                      updateStrategy(setSellStrategies, updatedStrategy)
                    }
                    removeStrategy={(strategy) =>
                      removeStrategy(setSellStrategies, strategy)
                    }
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider />
                </Grid.Col>
              </Fragment>
            );
          })
        )}
        {!disableRepeatStrategies && (
          <Fragment>
            {/*Repeat Strategies Buttons*/}
            <Grid.Col span={12}>
              <Group>
                {Object.values(Strategies).map(({ type, model, Button }) => {
                  if (type !== "repeat") return null;

                  return (
                    <Button
                      key={model.name}
                      onClick={() => addStrategy(setRepeatStrategies, model)}
                      disabled={!!repeatStrategies.length}
                    />
                  );
                })}
              </Group>
            </Grid.Col>
            {/*Repeat Strategies Divider*/}
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            {/*Repeat Strategies Components*/}
            {!repeatStrategies.length ? (
              <Grid.Col span={12}>
                <Text fw={700} align="center" my="2rem">
                  No repeat strategies
                </Text>
              </Grid.Col>
            ) : (
              repeatStrategies.map((strategy) => {
                const Strategy = Strategies[strategy.name]?.component;

                if (!Strategy) {
                  return (
                    <Unknown
                      strategy={strategy}
                      removeStrategy={(strategy) =>
                        removeStrategy(setSellStrategies, strategy)
                      }
                    />
                  );
                }

                return (
                  <Fragment key={strategy.id}>
                    <Grid.Col span={12}>
                      <Strategy
                        symbol={symbol}
                        strategy={strategy}
                        updateStrategy={(updatedStrategy) =>
                          updateStrategy(setRepeatStrategies, updatedStrategy)
                        }
                        removeStrategy={(strategy) =>
                          removeStrategy(setRepeatStrategies, strategy)
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Divider />
                    </Grid.Col>
                  </Fragment>
                );
              })
            )}
          </Fragment>
        )}
        <Grid.Col span={6}>
          <Button
            fullWidth
            variant="light"
            color="blue"
            onClick={onSubmit}
            loading={process?.inProcess}
            disabled={process?.inProcess}
          >
            Submit
          </Button>
        </Grid.Col>
        <Grid.Col span={6}>
          <Button fullWidth variant="white" color="gray" onClick={onClose}>
            Close
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}
