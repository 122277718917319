import { React, useState } from "react";
import { Select, Text } from "@mantine/core";
import { AmountStrategyInitModel } from "../index";

export default function AmountPercentPicker({
  amountStrategy,
  setAmountStrategy,
  ...props
}) {
  const [options, setOptions] = useState(() => {
    let initial = ["0", "25", "50", "75", "100"];
    if (!initial.includes(String(amountStrategy.amountPercent))) {
      initial.push(String(amountStrategy.amountPercent));
      initial.sort((a, b) => a - b);
    }
    return initial;
  });

  return (
    <Select
      label={<Text fw={700}>Amount (%)</Text>}
      placeholder="Amount (%)"
      maxDropdownHeight={400}
      data={options}
      value={String(amountStrategy.amountPercent)}
      onChange={(value) => {
        setAmountStrategy((current) => ({
          ...AmountStrategyInitModel,
          minInvestAmount: current.minInvestAmount,
          maxInvestAmount: current.maxInvestAmount,
          amountPercent: value ? Number(value) : 0,
        }));
      }}
      {...props}
      withinPortal={true}
      searchable={true}
      creatable={true}
      getCreateLabel={(query) => `+ Create ${query}`}
      onCreate={(query) => {
        const item = { value: query, label: query };
        setOptions((current) => [...current, item]);
        return item;
      }}
    />
  );
}
