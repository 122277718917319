import { createSlice } from "@reduxjs/toolkit";

export const WATCH_STARRED = "STARRED::WATCH";
export const ADD_STARRED = "STARRED::ADD";
export const DELETE_STARRED = "STARRED::DELETE";
export const RESET_ALL_STARRED = "STARRED::RESET_ALL";
export const TOGGLE_STARRED = "STARRED::TOGGLE_STARRED";

export const watchStarred = ({} = {}) => ({ type: WATCH_STARRED });
export const toggleStarred = ({ symbol, platform, value }) => ({
  type: TOGGLE_STARRED,
  symbol,
  platform,
  value,
});
export const resetAllStarred = () => ({ type: RESET_ALL_STARRED });

export const starredSlice = createSlice({
  name: "starred",
  initialState: {},
  reducers: {
    add: (state, action) => {
      return {
        ...state,
        tokens: {
          ...state.tokens,
          [action.payload.symbol]: {
            symbol: action.payload.symbol,
            platform: action.payload.platform,
            created: action.payload.created,
          },
        },
      };
    },
    delete: (state, action) => {
      let updated = JSON.parse(JSON.stringify(state));
      delete updated.tokens[action.payload.symbol];
      return updated;
    },
    set: (state, action) => {
      return { ...action.payload };
    },
  },
});
