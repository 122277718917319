import { Fragment } from "react";
import {
  IconPlus,
  IconCirclePlus,
  IconCircleMinus,
  IconReload,
} from "@tabler/icons-react";
import {
  Avatar,
  Box,
  Button,
  Group,
  Text,
  UnstyledButton,
} from "@mantine/core";

import { Strategies } from "../config";

const StrategyButton = ({ type, openModal, isFake, strategy, disable }) => {
  let color;
  if (isFake) color = "gray";
  else if (type === "buy") color = "teal";
  else if (type === "sell") color = "red";
  else if (type === "repeat") color = "violet";

  let Icon;
  if (type === "buy") Icon = IconCirclePlus;
  else if (type === "sell") Icon = IconCircleMinus;
  else if (type === "repeat") Icon = IconReload;

  return (
    <UnstyledButton key={strategy?.id} onClick={openModal} disabled={disable}>
      <Group>
        <Avatar size={40} color={color}>
          <Icon />
        </Avatar>
        <div>
          <Text fw={700} size="sm">
            {strategy?.title || strategy?.name}
          </Text>
          <Text size="sm">
            {Strategies[strategy?.name]?.paramsTitle(strategy) ||
              strategy?.name}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  );
};

export function StrategyPickerBoard({
  openModal,
  isFake,
  buyStrategies = [],
  sellStrategies = [],
  repeatStrategies = [],
  disableButtons,
  children,
}) {
  return (
    <Box
      shadow="xs"
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "0.5rem",
      }}
    >
      <Group align="center" justify="center">
        {buyStrategies?.length ||
        sellStrategies?.length ||
        repeatStrategies.length ? (
          <Fragment>
            {buyStrategies.map((strategy) => {
              return (
                <Fragment key={strategy.id}>
                  <StrategyButton
                    key={strategy.id}
                    type="buy"
                    openModal={openModal}
                    isFake={isFake}
                    strategy={strategy}
                    disable={disableButtons}
                  />
                  {strategy?.params?.and && <b>+</b>}
                </Fragment>
              );
            })}
            {sellStrategies.map((strategy) => {
              return (
                <Fragment key={strategy.id}>
                  <StrategyButton
                    key={strategy.id}
                    type="sell"
                    openModal={openModal}
                    isFake={isFake}
                    strategy={strategy}
                    disable={disableButtons}
                  />
                  {strategy?.params?.and && <b>+</b>}
                </Fragment>
              );
            })}
            {repeatStrategies.map((strategy) => {
              return (
                <Fragment key={strategy.id}>
                  <StrategyButton
                    key={strategy.id}
                    type="repeat"
                    openModal={openModal}
                    isFake={isFake}
                    strategy={strategy}
                    disable={disableButtons}
                  />
                </Fragment>
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            <Button
              variant="light"
              color={isFake ? "gray" : "teal"}
              onClick={openModal}
              leftIcon={<IconPlus />}
            >
              Strategy
            </Button>
          </Fragment>
        )}
        {children}
      </Group>
    </Box>
  );
}
