import { React } from "react";
import { TextInput, Select, Text } from "@mantine/core";
import { AmountStrategyInitModel } from "../index";

export default function AmountPicker({ amountStrategy, setAmountStrategy }) {
  return (
    <TextInput
      label={<Text fw={700}>Amount</Text>}
      placeholder="Amount"
      value={String(amountStrategy.amount)}
      onChange={(e) => {
        setAmountStrategy((current) => ({
          ...AmountStrategyInitModel,
          minInvestAmount: current.minInvestAmount,
          maxInvestAmount: current.maxInvestAmount,
          amount: e.target.value ? Number(e.target.value) : 0,
        }));
      }}
    />
  );
}
