import { Center, Grid, Text, Title, ActionIcon } from "@mantine/core";
import { IconStarFilled } from "@tabler/icons-react";

import Table, { TH } from "../../common/components/Table";
import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";
import { getDurationInMin, toK } from "../../common/helpers";
import { time } from "uniqid";
import { toggleStarred } from "../../common/data/starred/redux";
import { useDispatch, useSelector } from "react-redux";
import { Platform, Symbol, SymbolTH } from "../common";

export default function PumpTable({
  token,
  tokens,
  sortBy,
  sortDir,
  setSortBy,
  setSortDir,
}) {
  return (
    <Table>
      <Thead
        sortBy={sortBy}
        sortDir={sortDir}
        setSortBy={setSortBy}
        setSortDir={setSortDir}
      />
      <Tbody token={token} tokens={tokens} />
    </Table>
  );
}

const Thead = ({ sortBy, sortDir, setSortBy, setSortDir }) => {
  return (
    <thead>
      <tr>
        <SymbolTH />
        <TH
          title="mnr"
          sortBy="minorGrowth"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="mjr"
          sortBy="majorGrowth"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="flΔ"
          sortBy="flDelta"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        {/*<TH*/}
        {/*  title="Δ"*/}
        {/*  sortBy="delta"*/}
        {/*  currentSortBy={sortBy}*/}
        {/*  currentSortDir={sortDir}*/}
        {/*  setSortBy={setSortBy}*/}
        {/*  setSortDir={setSortDir}*/}
        {/*/>*/}
        <TH
          title="🕒"
          sortBy="lastAlertDate"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH title="tf" sortBy="tf" />
        {/*<TH*/}
        {/*  title="cnt"*/}
        {/*  sortBy="count"*/}
        {/*  currentSortBy={sortBy}*/}
        {/*  currentSortDir={sortDir}*/}
        {/*  setSortBy={setSortBy}*/}
        {/*  setSortDir={setSortDir}*/}
        {/*/>*/}
        {/*<TH*/}
        {/*  title="⌛"*/}
        {/*  sortBy="flTime"*/}
        {/*  currentSortBy={sortBy}*/}
        {/*  currentSortDir={sortDir}*/}
        {/*  setSortBy={setSortBy}*/}
        {/*  setSortDir={setSortDir}*/}
        {/*/>*/}
        <TH title="plt" />
        <TH title="btn" />
      </tr>
    </thead>
  );
};

const Tbody = ({ token, tokens }) => {
  const dispatch = useDispatch();
  const starred = useSelector((state) => state.starred);

  return (
    <tbody>
      {(token ? [token] : tokens).map(
        ({
          symbol,
          platform,
          ll,
          delta,
          minorGrowth,
          majorGrowth,
          avgPcIn5m,
          flDelta,
          count,
          lastAlertTfs,
          lastAlertDate,
          flTime,
        }) => {
          let timeAgo = getDurationInMin(new Date(), lastAlertDate);
          if (timeAgo < 60) timeAgo = `${Math.round(timeAgo)}m`;
          else timeAgo = `${Math.round(timeAgo / 60)}h`;

          if (flTime < 60) flTime = `${Math.round(flTime)}m`;
          else flTime = `${Math.round(flTime / 60)}h`;

          let onlySymbol = symbol.replace("USDT", "");

          return (
            <tr key={symbol}>
              <td>
                <Symbol symbol={symbol} platform={platform} ll={ll} />
              </td>
              <td>
                <Text size="sm" weight={600} align="center" color="teal.9">
                  {toK(minorGrowth)}%{" "}
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center" color="teal.9">
                  {toK(majorGrowth)}%{" "}
                </Text>
              </td>
              <td>
                <Center>
                  <Text
                    size="sm"
                    weight={600}
                    color={
                      flDelta >= 1
                        ? "green.9"
                        : flDelta >= 0
                        ? "dark.9"
                        : "red.9"
                    }
                  >
                    {flDelta === undefined
                      ? ""
                      : flDelta >= 1
                      ? `+${flDelta.toFixed(0)}%`
                      : `${flDelta.toFixed(0)}%`}
                  </Text>
                </Center>
              </td>
              {/*<td>*/}
              {/*  <Center>*/}
              {/*    <Text*/}
              {/*      size="sm"*/}
              {/*      weight={600}*/}
              {/*      color={delta >= 0 ? "green.9" : "orange.9"}*/}
              {/*    >*/}
              {/*      {delta === undefined*/}
              {/*        ? ""*/}
              {/*        : delta >= 0*/}
              {/*        ? `+${delta.toFixed(0)}%`*/}
              {/*        : `${delta.toFixed(0)}%`}*/}
              {/*    </Text>*/}
              {/*  </Center>*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <Center>*/}
              {/*    <Text size="sm" weight={600}>*/}
              {/*      {count}*/}
              {/*    </Text>*/}
              {/*  </Center>*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <Center>*/}
              {/*    <Text size="sm" weight={600}>*/}
              {/*      {flTime}*/}
              {/*    </Text>*/}
              {/*  </Center>*/}
              {/*</td>*/}
              <td>
                <Center>
                  <Text size="sm" weight={600}>
                    {timeAgo} ago
                  </Text>
                </Center>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  {lastAlertTfs[0]}
                </Text>
              </td>
              <td>
                <Platform symbol={symbol} platform={platform} />
              </td>
              <td>
                <Center>
                  <ActionIcon
                    variant="transparent"
                    color={starred.tokens?.[onlySymbol] ? "teal.7" : "gray.2"}
                    onClick={() => {
                      dispatch(
                        toggleStarred({
                          symbol,
                          platform,
                          value: !!starred.tokens?.[onlySymbol],
                        })
                      );
                    }}
                  >
                    <IconStarFilled size="1.2rem" />
                  </ActionIcon>
                </Center>
              </td>
            </tr>
          );
        }
      )}
    </tbody>
  );
};
