import React, { useState, useMemo, Fragment } from "react";
import {
  Button,
  Grid,
  Center,
  Group,
  Text,
  Box,
  SegmentedControl,
  Alert,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { IconPlus, IconStar } from "@tabler/icons-react";

import NewOrder, { NewOrderShort } from "./NewOrder";
import Order from "./Order";

import { useEffect } from "react";
import { watchOrders } from "../common/data/orders/redux";
import { WATCH_PRICES } from "../common/data/currentPrice/redux";
import Mongo from "../common/sevices/mongo";
import { useSearchParams } from "react-router-dom";

const TI = () => {
  const [ti, setTi] = useState(null);

  useEffect(function getTrendIndexes() {
    const get = async () => {
      try {
        if (!Mongo.client) await Mongo.connect();

        let [w1, d1, h4] = await Promise.all([
          Mongo.client
            .db(Mongo.dbs.main)
            .collection(`trendIndex_1w`)
            .find({}, { sort: { _id: -1 }, limit: 1 }),
          Mongo.client
            .db(Mongo.dbs.main)
            .collection(`trendIndex_1d`)
            .find({}, { sort: { _id: -1 }, limit: 1 }),
          Mongo.client
            .db(Mongo.dbs.main)
            .collection(`trendIndex_4h`)
            .find({}, { sort: { _id: -1 }, limit: 1 }),
        ]);

        // console.log(w1, d1, h4);

        setTi({
          "1w": w1[0]?.gisFisStats?.trendIndex,
          "1d": d1[0]?.gisFisStats?.trendIndex,
          "4h": h4[0]?.gisFisStats?.trendIndex,
        });
      } catch (e) {
        console.log(e);
      }
    };

    get();
  }, []);

  if (!ti) return <div>...</div>;
  else
    return (
      <>
        <Grid>
          {/*<Grid.Col lg={12}>*/}
          {/*  <Center>*/}
          {/*    <Group>*/}
          {/*      {[*/}
          {/*        { interval: "1w", value: ti["1w"] },*/}
          {/*        { interval: "1d", value: ti["1d"] },*/}
          {/*        { interval: "4h", value: ti["4h"] },*/}
          {/*      ].map(({ interval, value }) => {*/}
          {/*        return (*/}
          {/*          <Text key={interval}>*/}
          {/*            <b>{interval}:</b>{" "}*/}
          {/*            <Text color={value >= 0 ? "teal" : "red"} span={true}>*/}
          {/*              <b>{value}</b>*/}
          {/*            </Text>*/}
          {/*          </Text>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </Group>*/}
          {/*  </Center>*/}
          {/*</Grid.Col>*/}
          {ti["1d"] >= -5 && ti["4h"] >= -5 ? (
            <Grid.Col lg={12}>
              <Center>
                <Alert color="green">
                  <Center>
                    <Group>
                      {[
                        { interval: "1w", value: ti["1w"] },
                        { interval: "1d", value: ti["1d"] },
                        { interval: "4h", value: ti["4h"] },
                      ].map(({ interval, value }) => {
                        return (
                          <Text key={interval}>
                            <b>{interval}:</b>{" "}
                            <Text
                              color={value >= 0 ? "teal" : "red"}
                              span={true}
                            >
                              <b>{value}</b>
                            </Text>
                          </Text>
                        );
                      })}
                    </Group>
                  </Center>
                  <Text color="green" component="span">
                    <b>FYI!</b>
                  </Text>{" "}
                  <b>Trend Index is favorable for short-term long positions!</b>
                </Alert>
              </Center>
            </Grid.Col>
          ) : (
            <Grid.Col lg={12}>
              <Center>
                <Alert color="red">
                  <Center>
                    <Group>
                      {[
                        { interval: "1w", value: ti["1w"] },
                        { interval: "1d", value: ti["1d"] },
                        { interval: "4h", value: ti["4h"] },
                      ].map(({ interval, value }) => {
                        return (
                          <Text key={interval}>
                            <b>{interval}:</b>{" "}
                            <Text
                              color={value >= 0 ? "teal" : "red"}
                              span={true}
                            >
                              <b>{value}</b>
                            </Text>
                          </Text>
                        );
                      })}
                    </Group>
                  </Center>
                  <Text color="red" component="span">
                    <b>Warning!</b>
                  </Text>{" "}
                  <b>
                    Trend Index is unfavorable for short-term long positions!
                  </b>
                </Alert>
              </Center>
            </Grid.Col>
          )}
        </Grid>
      </>
    );
};

const MainTradePage = ({
  showNewOrderForm,
  setShowNewOrderForm,
  sortedOrders,
}) => {
  return (
    <React.Fragment>
      {showNewOrderForm ? (
        <Grid.Col md={6} sm={12}>
          <NewOrder hideForm={() => setShowNewOrderForm(false)} />
        </Grid.Col>
      ) : (
        <Grid.Col span={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            leftIcon={<IconPlus />}
            onClick={() => setShowNewOrderForm(true)}
          >
            Create new order
          </Button>
        </Grid.Col>
      )}
      {sortedOrders.map((order) => {
        if (order.status === "closed" || order.status === 1000) {
          return null;
        }

        return (
          <Grid.Col key={order._id} md={6} sm={12}>
            <Order order={order} isFake={order.isFake} />
          </Grid.Col>
        );
      })}
    </React.Fragment>
  );
};

const Starred = () => {
  const [showType, setShowType] = useState("short");

  const starred = useSelector((state) => state.starred);

  let sortedArr = useMemo(() => {
    if (!starred || !starred?.tokens || !Object.keys(starred?.tokens).length)
      return [];
    return Object.values(starred.tokens).sort(
      (a, b) => new Date(b.created) - new Date(a.created)
    );
  }, [starred]);

  // console.log(starred?.tokens);

  if (!sortedArr.length) return null;
  // return (
  //   <Grid.Col lg={12}>
  //     <Center>
  //       <b>No starred projects</b>
  //     </Center>
  //   </Grid.Col>
  // );

  return (
    <Fragment>
      <Grid.Col lg={12}>
        <Center>
          <Group>
            <SegmentedControl
              value={showType}
              onChange={(value) => setShowType(value)}
              transitionDuration={0}
              data={[
                { label: "Full", value: "full" },
                { label: "Short", value: "short" },
              ]}
            />
            <Text>
              <b>{sortedArr.length} token(s)</b>
            </Text>
          </Group>
        </Center>
      </Grid.Col>
      {showType === "full" &&
        sortedArr.map(({ symbol, platform, created }) => {
          return (
            <Grid.Col lg={6} key={symbol}>
              <NewOrder initSymbol={symbol} initPlatform={platform} />
            </Grid.Col>
          );
        })}
      {showType === "short" &&
        sortedArr.map(({ symbol, platform, created }) => {
          return (
            <Grid.Col lg={4} md={6} sm={6} xs={12} key={symbol}>
              <NewOrderShort initSymbol={symbol} initPlatform={platform} />
            </Grid.Col>
          );
        })}
    </Fragment>
  );
};

export default function Orders() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [visibilityGroup, setVisibilityGroup] = useState("all");

  useEffect(() => {
    dispatch({ type: WATCH_PRICES });
  }, []);

  const [showNewOrderForm, setShowNewOrderForm] = useState(true);

  // const { sendMessage, lastMessage, readyState } = useWebSocket(
  //   `wss://stream.binance.com:9443/ws/btcusdt@kline_1s/eduusdt@kline_1s`
  // );
  // const data = lastMessage && JSON.parse(lastMessage.data);
  // const currentPrice = parseFloat(data?.p) || 0;

  useEffect(
    function updateShowParam() {
      if (
        searchParams.get("show") &&
        visibilityGroup !== searchParams.get("show")
      ) {
        setVisibilityGroup(searchParams.get("show"));
      }
    },
    [searchParams.get("show")]
  );

  useEffect(() => {
    dispatch(watchOrders());
  }, []);

  let orders = useSelector((state) => state.orders);

  let sortedOrders = useMemo(() => {
    if (!orders?.real?.length) return [];

    let sorted = [...orders?.real].sort(
      (a, b) => new Date(b.created) - new Date(a.created)
    );

    return sorted.filter((order) => {
      if (visibilityGroup === "all") return true;
      if (visibilityGroup === "main") return !order.isHiddenGroup;
      else if (visibilityGroup === "hidden") return order.isHiddenGroup;
      else if (visibilityGroup === "bot") return order.source === "bot";
      else if (visibilityGroup === "user") return order.source === "user";
      else if (visibilityGroup === "real") return !order.isFake;
      else if (visibilityGroup === "fake") return order.isFake;
      else if (visibilityGroup === "buy") return order.status === "buy";
      else if (visibilityGroup === "sell") return order.status === "sell";
      else if (visibilityGroup === "none") return false;
    });
  }, [orders, visibilityGroup]);

  return (
    <Grid>
      <Grid.Col lg={12}>
        <Center>
          <Group>
            <SegmentedControl
              value={visibilityGroup}
              onChange={(value) => {
                setVisibilityGroup(value);
                setSearchParams((prev) => {
                  const newParams = new URLSearchParams(prev);
                  newParams.set("show", value);
                  return newParams;
                });
              }}
              transitionDuration={0}
              data={[
                { label: "All", value: "all" },
                // { label: "Main", value: "main" },
                // { label: "Hidden", value: "hidden" },
                { label: "User", value: "user" },
                { label: "Bot", value: "bot" },
                { label: "Real", value: "real" },
                { label: "Fake", value: "fake" },
                { label: "Buy", value: "buy" },
                { label: "Sell", value: "sell" },
                // { label: "None", value: "none" },
                {
                  value: "starred",
                  label: (
                    <Center>
                      {/*<IconStar size="1.3rem" />*/}
                      <IconStar size="1rem" />
                      <Box ml={10}>Starred</Box>
                    </Center>
                  ),
                },
              ]}
            />
          </Group>
        </Center>
      </Grid.Col>
      <Grid.Col lg={12}>
        <Center>
          <TI />
        </Center>
      </Grid.Col>
      {visibilityGroup !== "starred" ? (
        <MainTradePage
          showNewOrderForm={showNewOrderForm}
          setShowNewOrderForm={setShowNewOrderForm}
          sortedOrders={sortedOrders}
        />
      ) : (
        <Starred />
      )}
    </Grid>
  );
}
