import { useState } from "react";
import { React } from "react";
import { Select, Text } from "@mantine/core";
import { AmountStrategyInitModel } from "../index";

export default function AmountUsdPicker({ amountStrategy, setAmountStrategy }) {
  const [options, setOptions] = useState(() => {
    let initial = [
      "0",
      "10",
      "50",
      "100",
      "250",
      "500",
      "750",
      "1000",
      "1500",
      "2000",
      "2500",
      "3000",
      "4000",
      "5000",
      "7500",
      "10000",
    ];
    if (!initial.includes(String(amountStrategy.amountUsd))) {
      initial.push(String(amountStrategy.amountUsd));
      initial.sort((a, b) => a - b);
    }
    return initial;
  });

  return (
    <Select
      label={<Text fw={700}>Amount ($)</Text>}
      maxDropdownHeight={400}
      placeholder="Amount ($)"
      searchable={true}
      creatable={true}
      getCreateLabel={(query) => `+ Create ${query}`}
      onCreate={(query) => {
        const item = { value: query, label: query };
        setOptions((current) => [...current, item]);
        return item;
      }}
      data={options}
      value={String(amountStrategy.amountUsd)}
      onChange={(value) => {
        setAmountStrategy((current) => ({
          ...AmountStrategyInitModel,
          minInvestAmount: current.minInvestAmount,
          maxInvestAmount: current.maxInvestAmount,
          amountUsd: value ? Number(value) : 0,
        }));
      }}
      withinPortal={true}
    />
  );
}
