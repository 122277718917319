export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;

export const diffToPercent = (diff, digits = 2) =>
  toFixed((diff - 1) * 100, digits);

export const dateToIdDate = ({ date }) => {
  return `${new Date(date).getDate()}.${new Date(date).getMonth()}.${new Date(
    date
  ).getFullYear()}`;
};

export const idDateToNormalDate = ({ idDate }) => {
  let [day, month, year] = idDate.split(".");

  month = +month + 1;

  return new Date(`${month}.${day}.${year}`);
};

export const toFixed = (number, digits) =>
  Number(Number(number).toFixed(digits));

export function toFixedMeaningful(number, digits) {
  number = Number(number);

  if (isNaN(number)) {
    console.log("Provide correct number");
    // throw new Error("Provide correct number");
    return;
  }

  number = number.toFixed(15);
  number = number.replace(/\.?0*$/, "");

  const float = number.split(".")[1];

  if (!float) {
    return Number(number);
  }

  if (float.length >= digits) {
    let zeros = float.match(/^0*/);

    if (!zeros) {
      return Number(number);
    }

    return Number(Number(number).toFixed(zeros[0].length + digits));
  }

  return Number(number);
}

export function toK(number, to = 0) {
  if (number > 1000000000) {
    return `${toFixed(number / 1000000000, to)} b`;
  } else if (number > 1000000) {
    return `${toFixed(number / 1000000, to)} m`;
  } else if (number > 1000) {
    return `${toFixed(number / 1000, to)}k`;
  }

  return number.toFixed(to);
}

export const getBinanceSpotTradingPageUrl = (symbol) =>
  `https://www.binance.com/en/trade/${symbol.replace("USDT", "")}_USDT`;

export const getBybitSpotTradingPageUrl = (symbol) =>
  `https://www.bybit.com/en/trade/spot/${symbol.replace("USDT", "")}/USDT`;

export const getMexcSpotTradingPageUrl = (symbol) =>
  `https://www.mexc.com/exchange/${symbol.replace("USDT", "")}_USDT`;

export const getSpotTradingPageUrl = (symbol, platform) => {
  if (platform === "binance") return getBinanceSpotTradingPageUrl(symbol);
  else if (platform === "bybit") return getBybitSpotTradingPageUrl(symbol);
  else if (platform === "mexc") return getMexcSpotTradingPageUrl(symbol);
};

export const sleep = async (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const countDigits = (number, shift) => {
  let digits = String(number).split(".")[1]?.length;

  return shift ? digits + shift : digits;
};

export const getDuration = (t1, t2) => {
  const ms = new Date(t1) - new Date(t2);

  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let title = [];

  if (days) {
    title.push(`${days} d`);
    // title.push(`${hours % 24} hr`);
  } else if (hours) {
    title.push(`${hours} hr`);
    // title.push(`${minutes % 60} m`);
  } else if (minutes) {
    title.push(`${minutes} m`);
  } else if (seconds) {
    title.push(`${seconds} s`);
  }

  return title.join(" ");
};

export const getDurationInMin = (t1, t2, digits = 2) =>
  toFixed((new Date(t1) - new Date(t2)) / 1000 / 60, digits);

export const runJobOn = async ({ seconds, logger, job }) => {
  while (true) {
    try {
      if (new Date().getSeconds() === seconds) {
        await job();

        await sleep(3 * ONE_SECOND);
      }

      await sleep(500);
    } catch (e) {
      logger.error(e);
      await sleep(30 * ONE_SECOND);
    }
  }
};

export const runJobEvery = async ({ seconds, logger, job }) => {
  while (true) {
    try {
      await job();

      await sleep(seconds * ONE_SECOND);
    } catch (e) {
      logger.error(e);
      await sleep(30 * ONE_SECOND);
    }
  }
};

export const getAverage = (arrOfValues) => {
  return (
    arrOfValues.reduce((total, value) => total + value, 0) / arrOfValues.length
  );
};

export function getLowToHighGrowth(value1, value2) {
  return value1 > value2 ? value1 / value2 : value2 / value1;
}

export const findMinValueInArray = (arr) => {
  return Math.min(...arr);
};

export const findMaxValueInArray = (arr) => {
  return Math.max(...arr);
};

export const findMin = findMinValueInArray;
export const findMax = findMaxValueInArray;

export const chunkArrayFromStart = (arr, chunkBy) => {
  let multiplier = 0;

  const chunks = [];

  while (true) {
    const chunk = arr.slice(
      multiplier * chunkBy,
      multiplier * chunkBy + chunkBy
    );

    if (!chunk.length) {
      break;
    }

    chunks.push(chunk);

    multiplier++;

    if (chunk.length < chunkBy) {
      break;
    }
  }

  return chunks;
};

export const chunkArrayFromEnd = (arr, chunkBy) => {
  let multiplier = 1;

  const chunks = [];

  while (true) {
    let sliceStart = arr.length - multiplier * chunkBy;
    let sliceEnd = arr.length - (multiplier - 1) * chunkBy;

    if (sliceStart < 0) {
      sliceStart = 0;
    }

    const chunk = arr.slice(sliceStart, sliceEnd);

    if (!chunk.length) {
      break;
    }

    chunks.push(chunk);

    multiplier++;

    if (chunk.length < chunkBy) {
      break;
    }
  }

  return chunks.reverse();
};

export const sliceLastItem = (arr) => arr.slice(0, arr.length - 1);

export const sliceByTime = (arr, totalTime, sliceTime) => {
  return arr.slice(
    Math.ceil(arr.length - arr.length * (+sliceTime / totalTime)),
    arr.length
  );
};

export const cutCandles = (candles, closeTime) => {
  const result = [];

  for (let candle of candles) {
    if (new Date(closeTime) > new Date(candle.openTime)) {
      result.push(candle);
    }
  }

  return result;
};
export const frameCandles = (candles, startTime, endTime) => {
  const result = [];

  for (let candle of candles) {
    if (
      new Date(candle.openTime) >= new Date(startTime) &&
      new Date(candle.closeTime) <= new Date(endTime)
    ) {
      result.push(candle);
    }
  }

  return result;
};
