import { createSlice } from "@reduxjs/toolkit";

export const profitsSlice = createSlice({
  name: "profits",
  initialState: {},
  reducers: {
    update: (state, action) => {
      if (action.payload.set) {
        let current = { ...state };
        current[action.payload.key] = action.payload.value;
        return current;
      } else if (action.payload.unset) {
        let current = { ...state };
        delete current[action.payload.key];
        return current;
      }
    },
  },
});
