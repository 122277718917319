import { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

export default function TradingViewChartWidget({
  symbol = "BTCUSDT",
  platform = "BINANCE",
  width = "300px",
  height = "300px",
  interval = "1",
}) {
  const [show, setShow] = useState(false);

  const container = useRef();

  if (interval === "15m") interval = 15;
  else if (interval === "1h") interval = 60;
  else if (interval === "4h") interval = 4 * 60;
  else if (interval === "1d") interval = "1D";
  else if (interval === "1w") interval = "1W";

  const { ref: wrapper } = useInView({
    threshold: 0.5,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setShow(true);
    },
  });

  useEffect(() => {
    if (!show) return;
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "autosize": true,
          "symbol": "${platform}:${symbol}",
          "interval": "${interval}",
          "timezone": "${Intl.DateTimeFormat().resolvedOptions().timeZone}",
          "theme": "light",
          "style": "${interval}",
          "locale": "en",
          "hide_legend": true,
          "allow_symbol_change": true,
          "save_image": false,
          "calendar": false,
          "hide_volume": true,
          "support_host": "https://www.tradingview.com"
        }`;
    container.current.appendChild(script);
    return () => {
      if (container?.current) container.current.innerHTML = "";
    };
  }, [show, symbol, platform]);

  return (
    <div
      ref={wrapper}
      style={{
        height: "100%",
        width: "100%",
        minHeight: "400px",
        minWidth: "200px",
      }}
    >
      <div
        className="tradingview-widget-container"
        ref={container}
        style={{
          height: "100%",
          width: "100%",
          minHeight: "400px",
          minWidth: "200px",
        }}
      ></div>
    </div>
  );
  // const [show, setShow] = useState(false);
  //
  // if (interval === "15m") interval = 15;
  // else if (interval === "1h") interval = 60;
  // else if (interval === "4h") interval = 4 * 60;
  // else if (interval === "1d") interval = 24 * 60;
  // else if (interval === "1w") interval = 7 * 24 * 60;
  //
  // const { ref } = useInView({
  //   threshold: 0.5,
  //   triggerOnce: true,
  //   onChange: (inView) => {
  //     if (inView) setShow(true);
  //   },
  // });
  //
  // return (
  //   <div ref={ref} style={{ minWidth: "200px", minHeight: "400px" }}>
  //     {show && (
  //       <iframe
  //         height={height}
  //         width={width}
  //         src={`https://www.tradingview.com/widgetembed/?frameElementId=advanced-chart&symbol=${platform}:${symbol}&interval=${interval}&hidesidetoolbar=0&timezone=Europe/Istanbul&hideVolume=0&symboledit=1&saveimage=false&backgroundColor=#ffffff&gridColor=rgba(42, 46, 57, 0.06)&studies=[]&hideideas=1&theme=light&style=1&studies_overrides={}&overrides={}&enabled_features=[]&disabled_features=[]&locale=ru&utm_source=ru.tradingview.com&utm_medium=widget_new&utm_campaign=chart&utm_term=BINANCE:${symbol}#{"page-uri":"ru.tradingview.com/widget/"}`}
  //         frameBorder="0"
  //       ></iframe>
  //     )}
  //   </div>
  // );
}
